@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-9,400;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Silkscreen:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

p,
h1,
h2,
h3,
h4 {
  color: #080618;
}

body {
  background-color: #e4edff;
  font-family: "MyCustomFont", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

.pixelated {
  font-family: "Inter", sans-serif !important;
  font-family: "Silkscreen", cursive !important;
}

.swiper-pagination-bullet {
  background-color: white;
}
.swiper-pagination-bullet-active {
  background-color: #ee7101;
}

/* Regular Weight */
@font-face {
  font-family: "MyCustomFont";
  src: url("/fonts/Grotesk-Neue/OTF/FKGroteskNeue-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Light Weight */
@font-face {
  font-family: "MyCustomFont-Light";
  src: url("/fonts/Grotesk-Neue/OTF/FKGroteskNeue-Light.otf") format("opentype");
  font-weight: 300; /* Adjust weight as needed */
  font-style: normal;
}

/* Thin Weight */
@font-face {
  font-family: "MyCustomFont-Thin";
  src: url("/fonts/Grotesk-Neue/OTF/FKGroteskNeue-Thin.otf") format("opentype");
}

button,
p {
  font-family:
    "MyCustomFont-Thin",
    Futura PT,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
}

label,
a,
cite,
span {
  font-family:
    "MyCustomFont-Light",
    Futura PT,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
}

.light {
  font-family:
    "MyCustomFont-Light",
    Futura PT,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
}

a {
  -webkit-text-stroke-width: 0.03em;
}

h1,
h2,
.bold {
  font-family:
    "MyCustomFont",
    Futura PT,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji !important;
  // line-height: 1.2 !important;
  font-weight: 900;
}

h1 {
  -webkit-text-stroke-width: 0.025em;
}

.bold {
  -webkit-text-stroke-width: 0.02em;
}

.grid-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(100px, auto));
  width: 100%;
  max-width: 1200px; /* optional max-width */
  margin: auto;
}

.grid-item {
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(0.8rem, 1vw + 0.5rem, 1.2rem);
  padding: 1rem;
  border: 1px solid #ccc;
}

@keyframes cloud-animation {
  0% {
    // transform: translateY(0px);
    transform: rotateZ(3600deg);
  }

  50% {
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes slow-spin {
  0% {
    // transform: translateY(0px);
    rotate: 0deg;
  }

  50% {
  }

  100% {
    rotate: 360deg;
  }
}

.slow-spin {
  animation: slow-spin 60s linear infinite;
  transform-origin: center;
}

.cloud-animation {
  // animation: cloud-animation 1s ease-in-out forwards;
}

.custom-pagination .swiper-pagination-bullet {
  background-color: #ecf5ff; /* Change this to your desired color */
  opacity: 1; /* Ensures color is visible without Swiper's default opacity */
}

.custom-pagination .swiper-pagination-bullet-active {
  background-color: #ee7101; /* Color for the active bullet */
}

p,
button {
  font-size: 1.3rem;
}
